<template>
    <div class="unterseite kontakt">
        <div class="back-container">
            <button class="btn-unstyled btn-back" @click="goBack()">
                <i class="fas fa-chevron-left"></i>
                <span>{{lang.spielStarten.buttonTextBack}}</span>
            </button>
        </div>
        <h1>{{lang.kontakt.header1}}</h1>
        <p>{{lang.kontakt.paragraph}} <a href= "mailto:contact@beerpongfriends.com">contact@beerpongfriends.com</a>.</p>
    </div>
</template>

<script>
import store from '@/store/index'
import router from '@/router/index'

export default {
    name: 'Kontakt',
    data() {
        return {
        }
    },
    methods: {
        goBack() {
            router.go(-1);
        }
    },
    computed: {
        lang() {
            return store.getters.getLanguage;
        }
    }
}
</script>
<style lang="scss" scoped>
@import "../../assets/scss/base";

</style>
